<template>
  <div>
    <!--电影列表，与推荐列表-->
    <el-row id="movie-list">
      <el-breadcrumb
        class="bread"
        separator-class="el-icon-arrow-right"
      >
        <el-breadcrumb-item>
          系统中有<span class="result"> {{ totalSize }} </span> 个视频包含 <span class="result">{{ tag }}</span> 标签
        </el-breadcrumb-item>
      </el-breadcrumb>
      <!--电影列表-->
      <el-col v-loading="loading">
        <el-col v-for="(video, index) in dataList" :key="index" :md="6" :sm="12" :xs="12">
          <video-card :video="video" />
        </el-col>
        <!--        <el-col v-if="totalSize === 0" class="not-result" :md="6" :sm="12" :xs="12">
          <img src="@/assets/img/icon/not-result.png">
          <div>没有视频数据</div>
        </el-col>-->
        <!--
          分页按钮：
          page-size：每页显示条数
          total：总条数
          hide-on-single-page： 页数为一时隐藏
        -->
        <el-col :span="24" class="pagination">
          <el-pagination
            :small="screenWidth <= 768"
            layout="prev, pager, next"
            :page-size="pageSize"
            :current-page="currentPage"
            :total="totalSize"
            @current-change="handleCurrentChange"
          />
        </el-col>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import VideoCard from 'components/card/VideoCard'
import { getTagVideos } from '@/api/video'

export default {
  name: 'VideoTag',
  components: { VideoCard },
  data() {
    return {
      // 屏幕宽度, 为了控制分页条的大小
      screenWidth: document.body.clientWidth,
      currentPage: 1,
      pageSize: 12,
      totalSize: 0,
      dataList: [],
      tag: null,
      categoryId: 1,
      treeNode: [],
      defaultProps: {
        children: 'children',
        label: 'label',
        value: 'value'
      },
      loading: false
    }
  },
  created() {
    this.tag = this.$route.params.tag
    document.title = '包含 ' + this.tag + ' 标签的视频'
    this.getTagVideosWrapper(this.tag, this.currentPage)
  },
  mounted() {
    // 当窗口宽度改变时获取屏幕宽度
    window.onresize = () => {
      return () => {
        window.screenWidth = document.body.clientWidth
        this.screenWidth = window.screenWidth
      }
    }
  },
  methods: {
    handleNodeClick(data) {
      this.currentPage = 1
      this.dataList = []

      this.categoryId = data.value
      this.getTagVideosWrapper(this.tag, this.currentPage)
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage
      this.getTagVideosWrapper(this.tag, this.currentPage)
      // 回到顶部
      scrollTo(0, 0)
    },
    getTagVideosWrapper(tag, currentPage) {
      /* const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })*/

      this.loading = true
      getTagVideos(tag, currentPage).then(resp => {
        // loading.close()
        this.loading = false
        if (resp.code === 0) {
          const respData = resp.data
          this.dataList = respData.list
          this.totalSize = respData.totalSize
        } else {
          this.$notify({
            title: '提示',
            message: resp.msg,
            type: 'error',
            duration: 3000
          })
        }
      }).catch(error => {
        // loading.close()
        this.loading = false
        this.$notify({
          title: '提示',
          message: error.message,
          type: 'warning',
          duration: 3000
        })
      })
    }
  }
}
</script>

<style scoped>
/*处于手机屏幕时*/
@media screen and (max-width: 768px){
  #movie-list {
    padding-top: 8px;
    padding-left: 0.5%;
    padding-right: 0.5%;
  }

  .category-btn {
    padding-left: 0.5%;
    padding-right: 0.5%;
    padding-top: 3%;
    text-align: center;
  }
}

#movie-list {
  padding-top: 15px;
  padding-left: 6%;
  padding-right: 6%;
}

.result {
  color: red;
}

.pagination {
  text-align: center;
  padding: 10px;
}
</style>
